import APP_UTILITIES from '@/utilities/commonFunctions';
import { ColumnConfig, ColumnResizeData, ColumnResizeElementData, TableConfig, TablePayload } from './types';

const tableDataSetIdentifierSearchSuffix = 'search-config';
const tableDataSetIdentifierSortSuffix = 'sort-config';
const tableDataSetIdentifierResizeSuffix = 'resize-config';
const tableDataSetIdentifierColumnsSuffix = 'columns-config';

const persistTableData = (accountId: number, tableData: any, tableDataSetIdentifier: string) => {
  const authKey = APP_UTILITIES.authTokenKeyToManage()
    ? APP_UTILITIES.authTokenKeyToManage()
    : '';
  const tableDataForAccount = {
    [accountId]: tableData
  };
  APP_UTILITIES.setFilteredColumns(`${authKey}-${tableDataSetIdentifier}`, tableDataForAccount);
};

const retrieveTableData = (accountId: number, tableDataSetIdentifier: string): any | null => {
  const authKey = APP_UTILITIES.authTokenKeyToManage()
    ? APP_UTILITIES.authTokenKeyToManage()
    : '';
  const storedData = JSON.parse(APP_UTILITIES.getFilteredColumns(`${authKey}-${tableDataSetIdentifier}`) || 'null');
  if (storedData) {
    return storedData[accountId];
  }
  return null;
};

const persistTableSearchConfig = (accountId: number, tableConfig: TableConfig) => {
  const persistedTableDataForAccount = tableConfig.payload;
  const storageKey = `${tableConfig.tableName}-${tableDataSetIdentifierSearchSuffix}`;

  persistTableData(accountId, persistedTableDataForAccount, storageKey);
};

const persistTableSortConfig = (accountId: number, tableConfig: TableConfig) => {
  const persistedTableDataForAccount = tableConfig.payload;
  const storageKey = `${tableConfig.tableName}-${tableDataSetIdentifierSortSuffix}`;

  persistTableData(accountId, persistedTableDataForAccount, storageKey);
};

const persistTableResizeConfig = (accountId: number, tableConfig: TableConfig, resize: ColumnResizeElementData) => {
  const { column, maxWidth, minWidth } = resize;
  const newColumnResizeData: ColumnResizeData = {
    id: column.id,
    maxWidth,
    minWidth
  };

  const tableResizeData = [...tableConfig.resizesData];

  const existingResizeData = tableResizeData.findIndex((x: ColumnResizeData) => x.id === newColumnResizeData.id);
  const didFindExistingResizeData = existingResizeData >= 0;
  if (didFindExistingResizeData) {
    tableResizeData[existingResizeData] = newColumnResizeData;
  }
  else {
    tableResizeData.push(newColumnResizeData);
  }

  const persistedTableDataForAccount = tableResizeData;
  const storageKey = `${tableConfig.tableName}-${tableDataSetIdentifierResizeSuffix}`;

  persistTableData(accountId, persistedTableDataForAccount, storageKey);
};

const persistTableColumnConfig = (accountId: number, tableConfig: TableConfig) => {
  const persistedTableDataForAccount = tableConfig.displayColumn;
  const storageKey = `${tableConfig.tableName}-${tableDataSetIdentifierColumnsSuffix}`;

  persistTableData(accountId, persistedTableDataForAccount, storageKey);
};

const restoreTableSearchConfig = (accountId: number, tableConfig: TableConfig) => {
  const storageKey = `${tableConfig.tableName}-${tableDataSetIdentifierSearchSuffix}`;
  const searchConfig: TablePayload = retrieveTableData(accountId, storageKey) || tableConfig.payload;

  tableConfig.payload.isExactMatch = searchConfig.isExactMatch;
  tableConfig.payload.search = searchConfig.search;
  tableConfig.payload.searchField = searchConfig.searchField;
  if (searchConfig.searchField) {
    tableConfig.search = { [searchConfig.searchField]: searchConfig.search };
  }
};

const restoreTableSortConfig = (accountId: number, tableConfig: TableConfig) => {
  const storageKey = `${tableConfig.tableName}-${tableDataSetIdentifierSortSuffix}`;
  const sortConfig: TablePayload = retrieveTableData(accountId, storageKey) || tableConfig.payload;

  tableConfig.payload.sortDir = sortConfig.sortDir;
  tableConfig.payload.sortField = sortConfig.sortField;
};

const restoreTableResizeConfig = (accountId: number, tableConfig: TableConfig) => {
  const storageKey = `${tableConfig.tableName}-${tableDataSetIdentifierResizeSuffix}`;
  const resizeConfig: ColumnResizeData[] = (retrieveTableData(accountId, storageKey) || tableConfig.resizesData);

  tableConfig.resizesData = resizeConfig;
};

const restoreTableColumnConfig = (accountId: number, tableConfig: TableConfig) => {
  const storageKey = `${tableConfig.tableName}-${tableDataSetIdentifierColumnsSuffix}`;
  const columnConfig: ColumnConfig[] = (retrieveTableData(accountId, storageKey) || tableConfig.displayColumn);

  tableConfig.displayColumn = columnConfig;
};

export default {
  persistTableSearchConfig,
  persistTableSortConfig,
  persistTableResizeConfig,
  persistTableColumnConfig,

  restoreTableSearchConfig,
  restoreTableSortConfig,
  restoreTableResizeConfig,
  restoreTableColumnConfig
};