


































































































import { Component, Vue } from 'vue-property-decorator';
import SiteLocationsModule from '@/store/modules/SiteLocations/module';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import { ActionDropdownItem } from '@/commoncomponents/actionsdropdowncomponent/types';
import ActionsDropdown from '@/commoncomponents/actionsdropdowncomponent/ActionsDropdown.vue';
import APP_UTILITIES from '@/utilities/commonFunctions';
import AddEditSite from './AddEditSite.vue';
import { GetSiteLocationsRequest, DeleteSiteLocationRequest, SiteLocation, VerifiedSiteLocation } from '@/Model/siteLocations/types';
import DeletePopUp from '@/popupcomponents/deletePopUp/deletePopUp.vue';
import SiteLinkedToSessionPopUp from '@/popupcomponents/siteLinkedToSessionPopUp/siteLinkedToSessionPopUp.vue';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import SmartTableContainer from '@/commoncomponents/smartTableComponents/SmartTableContainer.vue';
import tableStore from '@/store/modules/table';
import { ColumnResizeData, ColumnResizeElementData, TableConfig, TableHeaderConfig } from '@/commoncomponents/smartTableComponents/types';
import tablePersistanceService from '@/commoncomponents/smartTableComponents/tablePersistanceService';
import table from '@/store/modules/table';

const screenText = new ScreenText();
const selectedSiteTemplate: SiteLocation = {
  id: 1,
  siteLocationName: '',
  location: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateCode: '',
    zip: ''
  }
};
const selectedEditSite: VerifiedSiteLocation | null = null;

@Component({
  components: {
    ActionsDropdown,
    AddEditSite,
    DeletePopUp,
    SiteLinkedToSessionPopUp,
    SmartTableContainer,
    BouncingPreloaderComponent,
  }
})
export default class Sites extends Vue {
  private allDropdownMenuItems: ActionDropdownItem[] =
    APP_CONST.SITES_ACTIONS_DROPDOWN;
  private currentUserRole: number = +(
    APP_UTILITIES.getCookie('highest_role') || APP_CONST.ZERO
  );
  private allowedRoles = [
    APP_CONST.ROLES.BX_SUPER_ADMIN,
    APP_CONST.ROLES.BX_ADMIN,
    APP_CONST.ROLES.ACCOUNT_ADMIN,
    APP_CONST.ROLES.PROGRAM_ADMIN,
  ];
  private allowedRolesForDelete = [
    APP_CONST.ROLES.BX_SUPER_ADMIN,
    APP_CONST.ROLES.BX_ADMIN,
    APP_CONST.ROLES.ACCOUNT_ADMIN,
  ];
  siteLocationsModule = SiteLocationsModule;
  screenText = screenText;
  availableDropdownMenuItems: ActionDropdownItem[] = [];
  selectedSite = selectedSiteTemplate;
  isAddSiteVisible = false;
  isDeletePopupVisible = false;
  isSiteLinkedToSessionPopUpVisible = false;
  isEditMode = false;
  selectedEditSite = selectedEditSite;

  // TODO: (REFACTOR) This should really get pulled from the url via a route param
  accountId = +(APP_UTILITIES.getCookie('accountId') || 0);

  tableHeaders: TableHeaderConfig = {
    FIXED: [{
      name: 'Name',
      value: 'siteLocationName',
      sortSearchField: 'siteLocationName',
      action: true,
      isOpen: false,
      width: '440px',
      isSort: true,
      isSearch: true,
      isSearchList: false,
      display: true
    }],
    MOVABLE: [{
      name: 'Address',
      value: 'locationAddress',
      sortSearchField: 'addressLine1',
      action: true,
      isOpen: false,
      width: '780px',
      isSort: true,
      isSearch: true,
      isSearchList: false,
      display: true
    }]
  };

  tableConfig: TableConfig = {
    tableName: 'site-locations-table',
    isSearching: false,
    headers: this.tableHeaders,
    initialPage: 0,
    keys: ['siteLocationName', 'locationAddress'],
    displayColumn: this.tableHeaders.MOVABLE,// TODO: Rerieve in local storage, default if not present
    isResizer: true,
    isSortingSearching: true,
    payload: {
      id: 0,
      active: 0,
      search: '',
      page: 1,
      count: 25,
      sortField: 'siteLocationName',
      sortDir: 1,
      searchField: '',
      isExactMatch: false
    },
    search: {},
    resizesData: [] as ColumnResizeData[]
  };

  // TODO (REFACTOR): This will need to be reworked when role checks are centralized
  canPerformActions = this.allowedRoles.some(x => x === this.currentUserRole);
  canPerformDelete = this.allowedRolesForDelete.some(x => x === this.currentUserRole);

  performAction(key: string) {
    switch (key) {
      case 'Add':
        this.openAddPanel();
        break;
      case 'Delete':
        this.openDeletePopup();
        break;
      default:
        break;
    }
  }

  openAddPanel() {
    this.isEditMode = false;
    this.isAddSiteVisible = true;
  }

  openDeletePopup() {
    this.siteLocationsModule.resetDeleteSite();
    this.isDeletePopupVisible = true;
  }

  async deleteSite() {
    await this.siteLocationsModule.softDeleteSiteLocation(
      { accountId: this.accountId, siteId: this.selectedSite.id } as DeleteSiteLocationRequest
    );
    if (this.siteLocationsModule.didSiteDeleteFail) {
      if (this.siteLocationsModule.siteLinkedSessions.length > 0) {
        this.isDeletePopupVisible = false;
        this.isSiteLinkedToSessionPopUpVisible = true;
      }
    }
    else {
      this.selectedSite = selectedSiteTemplate;
      table.mutateAllSelectedData([]);
      this.fetchSitesData();
      this.isDeletePopupVisible = false;
    }
  }

  filterActionDropdown() {
    this.availableDropdownMenuItems = this.canPerformDelete
      ? this.allDropdownMenuItems
      : APP_UTILITIES.findAndSplice(
        this.allDropdownMenuItems,
        'name',
        'Delete'
      );
  }

  openEditPanel(siteLocation: VerifiedSiteLocation) {
    this.isEditMode = true;
    this.isAddSiteVisible = true;
    this.selectedEditSite = siteLocation;
  }

  beforeMount() {
    this.isEditMode = false;
    this.filterActionDropdown();

    // Table stuff
    tablePersistanceService.restoreTableSearchConfig(this.accountId, this.tableConfig);
    tablePersistanceService.restoreTableSortConfig(this.accountId, this.tableConfig);
    tablePersistanceService.restoreTableColumnConfig(this.accountId, this.tableConfig);
    tablePersistanceService.restoreTableResizeConfig(this.accountId, this.tableConfig);

    this.tableConfig.payload.id = this.accountId;

    this.fetchSitesData();
  }

  get shouldShowCheckboxOnTable() {
    return this.canPerformDelete;
  }

  fetchSitesData() {
    const getSiteLocationsRequest: GetSiteLocationsRequest = this.tableConfig.payload;
    this.siteLocationsModule.getSiteLocationsForAccount(getSiteLocationsRequest);
  }

  destroyed() {
    tableStore.mutateSelectAll(false);
    tableStore.mutateFewCheckData('');
    tableStore.mutateAllSelectedData([]);
    tableStore.mutateUnSelectedData([]);
    tableStore.mutateSelectStatus('');
  }

  /**
   * TABLE FUNCTIONS
   */
  onFilterColumn() {
    tablePersistanceService.persistTableColumnConfig(this.accountId, this.tableConfig);
  }

  onFilterDisplayColumn() {
    tablePersistanceService.persistTableColumnConfig(this.accountId, this.tableConfig);
  }

  onPageSelected(page: number) {
    this.tableConfig.payload.page = page;
    this.fetchSitesData();
  }

  onPersistResize(resize: ColumnResizeElementData) {
    tablePersistanceService.persistTableResizeConfig(this.accountId, this.tableConfig, resize);
  }

  onSortData(sortObj: { sortField: string; sortDirection: number }) {
    this.tableConfig.payload.sortDir = sortObj.sortDirection;
    this.tableConfig.payload.sortField = this.findSearchSortField(sortObj.sortField);
    this.fetchSitesData();

    tablePersistanceService.persistTableSortConfig(this.accountId, this.tableConfig);
  }

  onSearchData(search: { searchField: string; isExactMatch: boolean }) {
    this.tableConfig.payload.searchField = this.findSearchSortField(search.searchField);
    this.tableConfig.payload.isExactMatch = search.isExactMatch;
    this.tableConfig.payload.search = this.tableConfig.search[search.searchField];
    this.fetchSitesData();

    tablePersistanceService.persistTableSearchConfig(this.accountId, this.tableConfig);
  }

  findSearchSortField(tableFieldName: string): string {
    const columnConfig = this.tableHeaders.FIXED.map(x => ({
      displayField: x.value,
      sortSearchField: x.sortSearchField
    }))
      .concat(this.tableHeaders.MOVABLE.map(x => ({
        displayField: x.value,
        sortSearchField: x.sortSearchField
      })))
      .find(x => x.displayField === tableFieldName);

    let searchSortField = '';
    if (columnConfig) {
      searchSortField = columnConfig.sortSearchField || columnConfig.displayField;
    }
    return searchSortField;
  }

  onSelectionChange(data: any) {
    this.selectedSite = data.selected
      ? data
      : selectedSiteTemplate;
  }
}
